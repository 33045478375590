<template>
  <div>
    <window-header></window-header>
    <nav-form :onlyIcons="true"></nav-form>
    <section>
      <article style="height: 160px; width:411px">
        <!--<div class="tabs" style="top:0px; left:0px; height:61px;">-->
        <!--<div class="field" name="code" widget="input" searchable label="Código" help="Código" placeholder="Cod" width="50px" readonly="edit" style="top:9px; left:30px;"  inputalign="center" fontweight="bold"/>-->
        <!--<div class="field" name="name" widget="select2" searchable label="Secuencia" help="Descripcion de la secuencia" placeholder="" width="270px" style="top:9px; left:95px;" 
                options='{
                    "Proveedores":"proveedor",
                    "Clientes":"cliente",
                    "Representantes":"representante",
                    "Depositos a Representantes":"deposito_representante",
                    "Articulos":"articulo",
                    "Modelos":"modelo",
                    "Almacenes":"almacen",
                    "Delegaciones":"delegacion",
                    "Seccion":"seccion",
					"Familia":"familia",
                    "Reparaciones":"reparacion",
                    "Pedidos de Compra":"pedido_compra",
                    "Albaranes de Compra":"albaran_compra",
                    "Facturas de Compra":"factura_compra",
                    "Pedidos de Ventas":"pedido_venta",
                    "Albaranes de Venta":"albaran_venta",
                    "Facturas de Venta":"factura_venta",
                    "Facturas de Liquidación de comisiones":"liquidacion_comision"
        }'/>-->
        <field
          name="name"
          widget="input"
          label="Secuencia"
          width="300px"
          :readonly="mode!='search'"
          searchable
          style="top:9px; left:30px;"
        />
        <field
          name="prefijo"
          widget="input"
          label="Prefijo"
          inline-label
          width="60px"
          style="top:50px; right:212px;"
        />
        <field
          name="rellenoceros"
          widget="input"
          label="Relleno de ceros"
          default="4"
          inline-label
          width="60px"
          style="top:70px; right:212px;"
        />
        <field
          name="siguiente"
          widget="input"
          label="Siguiente número"
          default="1"
          inline-label
          width="60px"
          style="top:90px; right:212px;"
        />
        <field
          name="sufijo"
          widget="input"
          label="Sufijo"
          inline-label
          width="60px"
          style="top:110px; right:212px;"
        />
        <center
          name="ejemplo"
          style="
                width: 160px;
                position: absolute;
                right: 28px;
                top: 80px;
                font-size: 14px;
                padding: 5px;
                border-radius: 5px;
                user-select: none;
                -webkit-user-select: none;
                background: #ccc"
        >{{ejemplo}}</center>
      </article>
    </section>
    <v-collapse-wrapper ref="main-collapse" :active="true">
      <div class="header" v-collapse-toggle>
        <div class="item-title">Listado</div>
        <div class="item-after">{{count}}</div>
      </div>
      <div class="my-content" v-collapse-content>
        <hot-table ref="hotTableComponent" :settings="htSettings"></hot-table>
      </div>
    </v-collapse-wrapper>
  </div>
</template>
<script>
import WindowMixin from "./../components/WindowMixin.vue";
import rFormMixin from "./../components/rFormMixin.vue";
import rTableMixin from "./../components/rTableMixin.vue";
export default {
  mixins: [WindowMixin, rFormMixin, rTableMixin],
  watch: {
    formData: {
      deep: true,
      handler() {
        this.calcularSecuencia();
        window.console.log(124);
      }
    }
  },
  data: function() {
    return {
      title: "Secuencias",
      dbAdapter: "secuencia",
      primary: "code",
      ejemplo: "-"
    };
  },
  methods: {
    calcularSecuencia: function() {
      var self = this;
      var prefijo = self.formData.prefijo || "";
      var sufijo = self.formData.sufijo || "";
      var rellenoceros = self.formData.rellenoceros || "";
      var siguiente = self.formData.siguiente || "";

      function replaceComands(str) {
        str = str || "";
        str = str.replace(/%A/g, function myFunction(x) {
          var d = new Date();
          return d.getFullYear();
        });
        str = str.replace(/%a/g, function myFunction(x) {
          var d = new Date();
          return d
            .getFullYear()
            .toString()
            .substr(2, 2);
        });
        str = str.replace(/%M/g, function myFunction(x) {
          var d = new Date();
          return ("0" + (d.getMonth() + 1)).slice(-2);
        });
        str = str.replace(/%m/g, function myFunction(x) {
          var d = new Date();
          return d.getMonth() + 1;
        });
        str = str.replace(/%D/g, function myFunction(x) {
          var d = new Date();
          return ("0" + d.getDate()).slice(-2);
        });
        str = str.replace(/%d/g, function myFunction(x) {
          var d = new Date();
          return d.getDate();
        });
        str = str.replace(/%S|%F|%O|%P|%E|%T|%C/g, function myFunction(x) {
          return x.charAt(1) + x.charAt(1);
        });
        return str;
      }

      prefijo = replaceComands(prefijo);
      sufijo = replaceComands(sufijo);
      self.ejemplo = prefijo + siguiente.padStart(rellenoceros, "0") + sufijo;
    }
  }
};
</script>
